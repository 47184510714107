import { createGlobalStyle } from "styled-components";

export const lightTheme = {
  primaryBackground: "#fff",
  primaryText: "#000",
  colors: {
    pink: "#e44cff",
  },
  premiumGoldGradient:
    "linear-gradient(74deg, rgba(217, 164, 85, 1) 0%, rgba(211, 183, 38, 1) 61%, rgba(231, 135, 40, 1) 100%)",
  bluePurpleGradient: `linear-gradient(
    135deg,
    rgb(5.882% 0.784% 100%) 0%,
    rgb(6.538% 0.92% 99.834%) 6.25%,
    rgb(8.479% 1.322% 99.343%) 12.5%,
    rgb(11.632% 1.974% 98.546%) 18.75%,
    rgb(15.875% 2.852% 97.473%) 25%,
    rgb(21.045% 3.921% 96.166%) 31.25%,
    rgb(26.944% 5.142% 94.674%) 37.5%,
    rgb(33.344% 6.466% 93.056%) 43.75%,
    rgb(40% 7.843% 91.373%) 50%,
    rgb(46.656% 9.22% 89.689%) 56.25%,
    rgb(53.056% 10.544% 88.071%) 62.5%,
    rgb(58.955% 11.765% 86.579%) 68.75%,
    rgb(64.125% 12.834% 85.272%) 75%,
    rgb(68.368% 13.712% 84.199%) 81.25%,
    rgb(71.521% 14.365% 83.402%) 87.5%,
    rgb(73.462% 14.766% 82.911%) 93.75%,
    rgb(74.118% 14.902% 82.745%) 100%
  );`,
  blueGreenGradient: `linear-gradient(
    180deg,
    hsl(240deg 100% 20%) 25%,
    hsl(273deg 100% 20%) 51%,
    hsl(291deg 100% 20%) 63%,
    hsl(307deg 100% 22%) 71%,
    hsl(317deg 100% 27%) 77%,
    hsl(324deg 100% 30%) 82%,
    hsl(330deg 100% 34%) 85%,
    hsl(334deg 100% 37%) 88%,
    hsl(345deg 72% 46%) 91%,
    hsl(355deg 66% 53%) 93%,
    hsl(6deg 64% 53%) 95%,
    hsl(17deg 62% 48%) 96%,
    hsl(26deg 66% 43%) 97%,
    hsl(34deg 66% 40%) 98%,
    hsl(41deg 61% 37%) 99%,
    hsl(49deg 50% 36%) 99%,
    hsl(60deg 38% 35%) 100%,
    hsl(74deg 30% 38%) 100%,
    hsl(91deg 22% 41%) 100%,
    hsl(113deg 16% 43%) 100%
  )`,
};

export const darkTheme = {
  primaryBackground: "#212A3B",
  primaryText: "#F2C393",
  colors: {
    pink: "#ff4cf2",
  },
  premiumGoldGradient: lightTheme.premiumGoldGradient,
  bluePurpleGradient: lightTheme.bluePurpleGradient,
  blueGreenGradient: lightTheme.blueGreenGradient,
};

export const GlobalStyles = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
  }

  h1, h2{
    font-family: 'Arvo', serif;
  }

  html, body, #root{
    background: ${(props) => props.theme.white};
    overflow-x: hidden;
  }

  p{
    display: block;
    margin-bottom: 0.5rem;

    &.warning{
      padding: 0.2rem 0.6rem;
      border: 1px solid #ff8989;
      background: #ffd4d4;
      border-radius: 6px;
      font-size: 0.9rem;
      display: inline-block;
      margin-top: 2rem;
      color: rgba(0, 0, 0, 0.8);
    }

    &.info{
      padding: 0.2rem 0.6rem;
      border: 1px solid blue;
      background: #9AD2FF;
      border-radius: 6px;
      font-size: 0.9rem;
      display: inline-block;
      margin-top: 2rem;
      color: rgba(0, 0, 0, 0.8);
    }
  }

  div.info{
      padding: 0.6rem;
      border: 1px solid blue;
      background: #9AD2FF;
      border-radius: 6px;
      font-size: 0.9rem;
      display: flex;
      margin: 1rem 0;
      color: rgba(0, 0, 0, 0.8);
      
      @media only screen and (max-width: 600px){
        display: block;
}

      img{
        border-radius: 6px;
        height: 8rem;
        margin-right: 0.6rem;
      }
  }

  h1{
    color: ${(props) => props.theme.black};
  }

  button{
    cursor: pointer;
  }

  article{
    margin-bottom: 2rem;
    max-width: 900px;
    padding: 0 16px;
    margin: 0 auto;

    h1, p{
      padding: 12px 0;
    }

    ul, ol{
      li{
        margin-left: 16px;
        padding-left: 8px;
        padding: 4px 0;
      }
    }
  }

  .flex {
    display: flex;
    &.space-between { justify-content: space-between }
    &.align-end { align-items: flex-end }
    &.justify-end { justify-content: flex-end }
    &.center { align-items: center }
  }
`;
