import { lightTheme, darkTheme, GlobalStyles } from "./styles";

import { ME } from "services/api";
import AppContext from "services/context";
import { API_URL as uri } from "services/env";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import LGPDModal from "components/LGPDModal";
import Portal from "components/Portal";
import SystemMessage from "components/SystemMessage";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import Sheet from "react-modal-sheet";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "services/Routes";
import { ThemeProvider } from "styled-components";

function App() {
  const httpLink = createHttpLink({ uri }),
    [token, setToken] = useState(null),
    [professional, setProfessional] = useState(null),
    [acceptedLGPDTerms, setAcceptedLGPDTerms] = useState(false),
    [showLGPDTerms, setShowLGPDTerms] = useState(false),
    [loading, setLoading] = useState(false),
    [showPremiumModal, setShowPremiumModal] = useState(false),
    [systemMessage, setSystemMessage] = useState({
      message: "",
      position: "bottom",
      type: "success",
    }),
    [user, setUser] = useState(null),
    [userIsLoading, setUserIsLoading] = useState(true),
    [theme, setTheme] = useState("light"), // Estado para tema
    authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    }),
    client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });

  // Detecta o tema do sistema operacional e configura o estado
  useEffect(() => {
    const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";
    setTheme(systemTheme);

    const listener = (e) => {
      setTheme(e.matches ? "dark" : "light");
    };

    const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    darkModeMediaQuery.addEventListener("change", listener);

    return () => {
      darkModeMediaQuery.removeEventListener("change", listener);
    };
  }, []);

  const currentTheme = theme === "dark" ? darkTheme : lightTheme;

  useEffect(() => {
    let persistentBrowserAppState = localStorage.getItem(
      "docsfilipelopesmedbr:appv1"
    );
    if (professional && !localStorage.getItem("docsfilipelopesmedbr:appv1")) {
      localStorage.setItem(
        "docsfilipelopesmedbr:appv1",
        JSON.stringify(professional)
      );
    }
    if (professional && !acceptedLGPDTerms) {
      if (persistentBrowserAppState) {
        const { lgpd } = JSON.parse(persistentBrowserAppState);
        if (lgpd) {
          setShowLGPDTerms(false);
          setAcceptedLGPDTerms(true);
        } else {
          setShowLGPDTerms(true);
        }
      } else {
        setShowLGPDTerms(true);
      }
    } else if (professional && acceptedLGPDTerms) {
      localStorage.setItem(
        "docsfilipelopesmedbr:appv1",
        JSON.stringify({
          ...professional,
          lgpd: acceptedLGPDTerms,
        })
      );
    } else if (!professional && acceptedLGPDTerms) {
      setAcceptedLGPDTerms(true);
      setShowLGPDTerms(false);
      localStorage.setItem(
        "docsfilipelopesmedbr:appv1",
        JSON.stringify({
          ...persistentBrowserAppState,
          lgpd: true,
        })
      );
    }
  }, [professional, acceptedLGPDTerms]);

  useEffect(() => {
    let persistentBrowserAppState = localStorage.getItem(
      "docsfilipelopesmedbr:appv1"
    );
    if (persistentBrowserAppState) {
      persistentBrowserAppState = JSON.parse(persistentBrowserAppState);
      setProfessional(persistentBrowserAppState);
    }
    let persistentToken = localStorage.getItem("docsfilipelopesmedbr:token");
    if (persistentToken) {
      setToken(persistentToken);
    }
  }, []);

  useEffect(() => {
    if (token) {
      localStorage.setItem("docsfilipelopesmedbr:token", token);
      client
        .query({
          query: ME,
        })
        .then(({ data: { me } }) => {
          setUser(me);
        })
        .catch((error) => {
          setUser(null);
          setSystemMessage({ message: error.message, type: "error" });
        })
        .finally(() => {
          setUserIsLoading(false);
        });
    } else {
      setUser(null);
      localStorage.removeItem("docsfilipelopesmedbr:token");
    }
  }, [token]);

  return (
    <AppContext.Provider
      value={{
        professional,
        setProfessional,
        acceptedLGPDTerms,
        setAcceptedLGPDTerms,
        showLGPDTerms,
        setShowLGPDTerms,
        loading,
        setLoading,
        systemMessage,
        setSystemMessage,
        user,
        setUser,
        userIsLoading,
        setToken,
      }}
    >
      <ApolloProvider client={client}>
        <ThemeProvider theme={currentTheme}>
          <GlobalStyles />
          <Router>
            <Routes />
            {loading &&
              createPortal(
                <Portal>
                  <SystemMessage dotsAnimation={true} type="info">
                    Carregando
                  </SystemMessage>
                </Portal>,
                document.getElementById("portal")
              )}
            {systemMessage.message &&
              createPortal(
                <Portal>
                  <SystemMessage
                    type={systemMessage.type}
                    position={systemMessage.position}
                    onClick={() =>
                      setSystemMessage({ ...systemMessage, message: "" })
                    }
                  >
                    {systemMessage.message}
                  </SystemMessage>
                </Portal>,
                document.getElementById("portal")
              )}
            {showLGPDTerms &&
              !acceptedLGPDTerms &&
              createPortal(
                <Portal>
                  <LGPDModal
                    onAccept={() => setAcceptedLGPDTerms(true)}
                    onDecline={() => setShowLGPDTerms(false)}
                  />
                </Portal>,
                document.getElementById("portal")
              )}
            <Sheet
              isOpen={showPremiumModal}
              onClose={() => setShowPremiumModal(false)}
            >
              <Sheet.Container>
                <Sheet.Header />
                <Sheet.Content>
                  Esse é um recurso Premium! Aqui vai o conteúdo.
                </Sheet.Content>
              </Sheet.Container>
              <Sheet.Backdrop />
            </Sheet>
          </Router>
        </ThemeProvider>
      </ApolloProvider>
    </AppContext.Provider>
  );
}

export default App;